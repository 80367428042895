import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation";
import * as basicStylesModule from "../../modules/basicStylesModule";
import * as backendModule from "../../modules/backendModule";
import { getParamsFromURLObject } from "../../modules/urlModule";
import useDefer from "../../modules/hooks/useDefer";

import { selectTrackingProfile, selectTrackingTeam } from "../../actions/trackingProfilesActions";

import { ReactComponent as Campaigns_img } from "./images/sidebar_campaigns.svg";
import { ReactComponent as Reports_img } from "./images/sidebar_reports.svg";
import { ReactComponent as Creatives_img } from "./images/sidebar_creatives.svg";
import { ReactComponent as Offers_img } from "./images/sidebar_offers.svg";
import { ReactComponent as OfferCopies_img } from "./images/sidebar_offercopies.svg";
import { ReactComponent as Sites_img } from "./images/sidebar_sites.svg";
import { ReactComponent as Integrations_img } from "./images/sidebar_integrations.svg";
import { ReactComponent as BalanceManagement_img } from "./images/sidebar_balanceManagement.svg";
import { ReactComponent as AccountManagement_img } from "./images/sidebar_accountManagement.svg";

import { ReactComponent as Users_img } from "./images/sidebar_users.svg";
import { ReactComponent as Datastore_img } from "./images/sidebar_datastore.svg";
import { ReactComponent as UserGroups_img } from "./images/sidebar_usergroups.svg";
import { ReactComponent as ApiTokens_img } from "./images/sidebar_apiTokens.svg";
import { ReactComponent as SocialManager_img } from "./images/sidebar_socialManager.svg";
import { ReactComponent as Data_img } from "./images/sidebar_data.svg";
import { ReactComponent as TrackEvents_img } from "./images/sidebar_trackEvents.svg";
import { ReactComponent as GlobalSharing_img } from "./images/sidebar_globalSharing.svg";
import { ReactComponent as ReportedURL_img } from "./images/sidebar_reportedURL.svg";
import { ReactComponent as BotDetect_img } from "./images/sidebar_botDetect.svg";

import ButtonWithDropdown from "../customComponents/ButtonWithDropdown";
import Spinner from "../customComponents/Spinner";
import Checkbox from "../customComponents/Checkbox";
import StyledButton from "../styledComponents/Button";

import YesNoModal from "../modals/YesNoModal";
import CreateLeadModal from "../modals/CreateLeadModal";
import { UserModal } from "../Header";

const Sidebar = (props) => {
    const curLocation = useLocation();
    const curNavigate = useNavigate();

    const reportFunctionsRef = React.useRef();
    const socialMediaFunctionsRef = React.useRef();
    const accountManagerFunctionsRef = React.useRef();

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const userIntegrationsSelector = useSelector(state => state?.userData?.userData?.Integrations ?? {});
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const currentTrackingProfiles = useSelector(state => state?.trackingProfiles ?? {});
    const refreshClickHandlers = useSelector(state => state?.siteFunctions?.headerRefreshHandlers ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [reportsVisible, setReportsVisible] = React.useState(false);
    const [socialMediaVisible, setSocialMediaVisible] = React.useState(false);
    const [accountManagerVisible, setAccountManagerVisible] = React.useState(false);
    const [notificationsMenu, setNotificationsMenu] = React.useState(false);
    const [hasUnreadNotifications, setHasUnreadNotifications] = React.useState(0);
    const [userModalVisible, setUserModalVisible] = React.useState(false);
    const [selectedGroup, setSelectedGroup] = React.useState();
    const [searchGroup, setSearchGroup] = React.useState();
    const [selectedProfiles, setSelectedProfiles] = React.useState();
    const [dropdownVisible, setdropdownVisible] = React.useState(false);
    const [submenu, setSubmenu] = React.useState(false);
    const [trackingProfiles, setTrackingProfiles] = React.useState();
    const [sidebarModalsTopPos, setSidebarTopPos] = React.useState(250);
    const [curURLParams, setCurURLParams] = React.useState({});

    const dropdownRef = React.useRef();
    const accountBtn = React.useRef();
    const accountArrow = React.useRef();
    const maxHeightValue = React.useRef();
    const searchRef = React.useRef();
    const sidebarRef = React.useRef();

    const mainDispatch = useDispatch();

    const checkSidebarActive = elem => {
        if (!elem) return curLocation.pathname === "/";
        return (curLocation.pathname.startsWith(`/${elem}`));
    };

    const checkIfHasBalance = () => {
        let tmp = currentTrackingProfiles?.selectedProfile;
        if (!tmp) return false;

        if (tmp.startsWith("fb-")) return true;
        if (tmp.startsWith("mg-")) return true;

        return false;
    };

    const getIntegrationImage = (integrationID) => {
        let curIntegration = getIntegration(integrationID);

        switch (curIntegration?.type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "tiktok": return "/images/integrations/integration_header_tiktok.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "adnow": return "/images/integrations/integration_adnow.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
            default: return "#";
        };
    };
    const getIntegration = integrationID => {
        let curProfile = currentTrackingProfiles?.profiles?.find(p => p.ID === integrationID);
        
        return curProfile ?? null;
    };
    const formatIntegrationName = name => {
        if (name) {
            if (name.startsWith("(Personal)")) {
                name = name.split("(Personal)").pop();
                return <>
                    <span style={{color: "rgb(69, 168, 255)"}} title="Personal account">(P)</span>
                    {name}
                </>
            };
        };
        return name;
    };
    const checkIfGenericProfile = id => {
        switch (id) {
            case "-1":
            case "-2":
            case "-3":
            case "md":
            case "mg":
            case "an":
                return true;
            default:
                return false;
        };
    };
    const searchProfiles = () => {
        let tmp = [];
        for (let profile of selectedProfiles.profiles) {
            if (profile.ID.toLowerCase().includes(searchRef.current.value.toLowerCase()) || profile.name.toLowerCase().includes(searchRef.current.value.toLowerCase())) {
                tmp.push(profile)
            }
        }
        setSearchGroup({ type: selectedProfiles.type, profiles: tmp });
    };

    const calculateModalTop = (e, curModal) => {
        setSidebarTopPos(e?.pageY ?? 200);

        const controllers = {
            "integrations": setdropdownVisible,
            "notifications": setNotificationsMenu,
            "user": setUserModalVisible
        };

        for (let key of Object.keys(controllers)) {
            if (key !== curModal) controllers[key](false);
        };
    };

    React.useEffect(() => {
        const closeDropdown = () => {
            if (dropdownVisible) setdropdownVisible(false);
        };

        window.addEventListener("click", closeDropdown);
        return () => {
            try {
                window.removeEventListener("click", closeDropdown);
            } catch { };
        };
    }, [dropdownVisible, notificationsMenu]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;

        maxHeightValue.current = currentTrackingProfiles.profiles.length * 60;
        if (currentTrackingProfiles.selectedProfile !== -1) {
            maxHeightValue.current += 61;
            if (submenu) setSubmenu(false);
        };
    }, [dropdownRef.current, currentTrackingProfiles]);

    React.useEffect(() => {
        setTrackingProfiles();
        if (!currentTrackingProfiles) return;
        let profilestmp = {};
        currentTrackingProfiles.profiles.map(item => {
            if (!profilestmp[item.ID.slice(0, 2)]) {
                profilestmp[item.ID.slice(0, 2)] = {
                    type: item.type,
                    profiles: [
                        {
                            ID: item.ID,
                            name: item.name,
                            banned: !!item.banned
                        }
                    ]
                }
            } else {
                profilestmp[item.ID.slice(0, 2)].profiles.push({
                    ID: item.ID,
                    name: item.name,
                    banned: !!item.banned
                })
            }
            setTrackingProfiles(profilestmp)
        });
    }, [currentTrackingProfiles]);

    React.useEffect(() => {
        setSearchGroup(selectedProfiles);
    }, [selectedProfiles]);

    React.useEffect(() => {
        if (!sidebarRef.current) return;

        let handler = () => setdropdownVisible(false);

        if (dropdownVisible) {
            sidebarRef.current.addEventListener("mouseleave", handler);
        };

        return () => {
            try {
                sidebarRef.current.removeEventListener("mouseleave", handler);
            } catch {};
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (!props.sidebarOpened) {
            setSidebarTopPos(50);
        };
    }, [props.sidebarOpened]);

    React.useEffect(() => {
        setCurURLParams(getParamsFromURLObject(String(window.location)));
    }, []);

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    });

    return <>
        <div ref={sidebarRef} className={`component__sidebar ${props.sidebarOpened ? 'component__sidebar--opened' : ''}`} onMouseEnter={() => {
            if (windowSize > 768) {
                props.setSidebar(true)
            }
        }} onMouseLeave={() => {
            if (windowSize > 768) {
                props.setSidebar(false)
            }
            if (reportFunctionsRef.current) reportFunctionsRef.current?.close?.();
            if (socialMediaFunctionsRef.current) socialMediaFunctionsRef.current?.close?.();
            if (accountManagerFunctionsRef.current) accountManagerFunctionsRef.current?.close?.();
        }}>
            <div className={`component__sidebar__logo ${(hasUnreadNotifications && !props.sidebarOpened) ? "component__sidebar__logo--notify" : ""}`}>
                <img src="/images/logo.svg" />
                <p>Scale Track</p>
                <div className="component__sidebar__logo__x" onClick={props.toggleSidebar()}>
                    <div className="component__sidebar__logo__x__line"></div>
                    <div className="component__sidebar__logo__x__line-2"></div>
                </div>
                {hasUnreadNotifications > 0 && <div className="component__sidebar__logo__unread">{hasUnreadNotifications}</div>}
            </div>

            <div className={`component__sidebar__head ${props.sidebarOpened ? "component__sidebar__head--active" : ""}`}>

                <div className="component__sidebar__head__account" ref={accountBtn} onClick={e => {
                    calculateModalTop(e, "integrations");
                    e.stopPropagation();
                    setdropdownVisible(pastState => !pastState)
                }} style={{
                    display: (userDataSelector?.UserInfo?.Flags?.isSocialMediaManager || userDataSelector?.UserInfo?.Flags?.isGuestManagerMD) ? "none" : null
                }}>
                    <div className="component__sidebar__head__account__user">
                        <p className="component__sidebar__head__account__user__bottom">
                            {currentTrackingProfiles?.selectedProfile === undefined ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : <>
                                {currentTrackingProfiles.selectedProfile === null ? <p>Error!</p> : <>
                                    <img src={getIntegrationImage(currentTrackingProfiles.selectedProfile)} />
                                    <p>{formatIntegrationName(getIntegration(currentTrackingProfiles?.selectedProfile)?.name ?? "?")}</p>
                                </>}
                            </>}
                        </p>
                    </div>
                    <div className="component__sidebar__head__account__arrow" ref={accountArrow} style={{
                        backgroundImage: 'url("/images/downArrow.svg")',
                        transform: dropdownVisible ? "rotate(180deg)" : null
                    }}></div>
                    <div className={`component__sidebar__head__account__dropdown ${dropdownVisible ? "component__sidebar__head__account__dropdown--active" : ""} ${submenu === true ? "component__sidebar__head__account__dropdown--custom" : ""}`} ref={dropdownRef} style={{
                        maxHeight: dropdownVisible ? `${maxHeightValue.current > 600 ? 600 : maxHeightValue.current}px` : 0
                    }}>
                        <div className="component__sidebar__head__account__dropdown__left" style={{
                            width: submenu ? '50%' : '100%',
                            transition: "all 0.3s ease"
                        }} >
                            {
                                trackingProfiles && Object.keys(trackingProfiles).map(profile => {
                                    return <p
                                        className={selectedGroup === profile ? "component__sidebar__head__account__dropdown__active" : null}
                                        onClick={(e) => {
                                            if (checkIfGenericProfile(profile)) {
                                                mainDispatch(selectTrackingProfile(trackingProfiles[profile].profiles[0].ID));
                                                setSelectedProfiles(null);
                                                setSubmenu(false);
                                            } else {
                                                e.stopPropagation();
                                                setSelectedProfiles(trackingProfiles[profile]);
                                                setSubmenu(true)
                                            }
                                            setSelectedGroup(profile);
                                        }}

                                    >
                                        <img src={getIntegrationImage(trackingProfiles[profile].profiles[0].ID)} />
                                        {checkIfGenericProfile(profile) ? trackingProfiles[profile].profiles[0].name : trackingProfiles[profile].type}
                                    </p>
                                })
                            }
                        </div>
                        <div className="component__sidebar__head__account__dropdown__right">
                            <input onClick={e => {
                                e.stopPropagation();
                            }} placeholder="Search" ref={searchRef} onChange={() => { searchProfiles() }} className="component__sidebar__head__account__dropdown__right__search" />
                            {searchGroup && searchGroup.profiles.map(profile => {
                                let profileID = profile.ID.split('-')[1];
                                checkIfGenericProfile(profile) ? profileID = '' : profileID = profileID;
                                return <p
                                    className={currentTrackingProfiles.selectedProfile === profile.ID ? "component__sidebar__head__account__dropdown__active" : null}
                                    onClick={() => mainDispatch(selectTrackingProfile(profile.ID))}
                                    style={{
                                        color: profile.banned ? "rgb(238, 102, 102)" : null
                                    }}
                                >
                                    <span style={{ left: "10px" }} >{profileID}</span>
                                    {profile.name}
                                </p>
                            })}
                        </div>
                    </div>
                </div>

                {checkIfHasBalance() && <ProfileBalance integrationID={currentTrackingProfiles?.selectedProfile} />}
                <CheckProofUploaded integrationID={currentTrackingProfiles?.selectedProfile} />

                <div className="component__sidebar__head__balance component__sidebar__head__clickable" onClick={() => refreshClickHandlers.forEach(i => i())}>
                    <p>
                        <img src="/images/header_refresh.svg" />
                        <span>Refresh</span>
                    </p>
                </div>
                <div className="component__sidebar__head__balance component__sidebar__head__notificationIcon component__sidebar__head__clickable" onClick={(e) => { calculateModalTop(e, "notifications"); setNotificationsMenu(!notificationsMenu); e?.stopPropagation(); }}>
                    <p>
                        <img src="/images/header_notifications.svg" />
                        <span>
                            <span>Notifications</span>
                            <span className="component__sidebar__head__notificationIcon__counter" style={{ display: hasUnreadNotifications > 0 ? null : "none" }}>{hasUnreadNotifications}</span>
                        </span>
                    </p>
                </div>
                <div className="component__sidebar__head__balance component__sidebar__head__clickable" onClick={(e) => { calculateModalTop(e, "user"); setUserModalVisible(m => !m); e?.stopPropagation(); }}>
                    <p>
                        <img src="/images/header_user.svg" />
                        <span>
                            <span>{userInfoSelector?.Username ?? "User"}</span>
                        </span>
                    </p>
                </div>
            </div>

            <UserNotifications
                notificationsMenu={notificationsMenu}
                setNotificationsMenu={setNotificationsMenu}
                setHasUnreadNotifications={setHasUnreadNotifications}
                notifications={props.notifications}
                style={{top: `${sidebarModalsTopPos}px`}}
            />
            <UserModal
                className="component__sidebar__head__notifications"
                visible={userModalVisible}
                setVisible={setUserModalVisible}

                userDataSelector={userDataSelector}
                currentTrackingProfiles={currentTrackingProfiles}
                setSelectedGroup={setSelectedGroup}
                setSearchGroup={setSearchGroup}
                setSelectedProfiles={setSelectedProfiles}
                selectTrackingTeam={selectTrackingTeam}
                style={{top: `${sidebarModalsTopPos}px`}}
            />

            {(!userInfoSelector?.Flags?.isSocialMediaManager && !userInfoSelector?.Flags?.isGuestManagerMD) ? <>
                <div onClick={() => animateNavigate("/")} className={`component__sidebar__item ${checkSidebarActive("") ? "component__sidebar__item--active" : ""}`}>
                    <Campaigns_img />
                    <p>Campaigns</p>
                </div>
                {/* <div onClick={() => animateNavigate("/optimize")} className={`component__sidebar__item ${checkSidebarActive("optimize") ? "component__sidebar__item--active" : ""}`}>
                    <Optimize_img />
                    <p>Optimize</p>
                </div> */}
                <div onClick={(e) => {
                    e.stopPropagation();
                    let tmp = document.querySelector(".component__sidebar__item--reports");
                    if (tmp) tmp.click();
                }} className={`component__sidebar__item ${checkSidebarActive("reports") ? "component__sidebar__item--active" : ""}`}>
                    <Reports_img />
                    <p>Reports</p>
                    <ButtonWithDropdown
                        headClassName="component__sidebar__item--reports"
                        headStyle={{ backgroundColor: reportsVisible ? (checkSidebarActive("reports") ? "#6C5DD3" : (themeSelector === "dark" ? "#232630" : "#F8F9FA")) : "transparent", pointerEvents: reportsVisible ? null : "none" }}
                        data={[
                            { name: "Marketing report", onClick: () => animateNavigate("/reports/marketing"), active: checkSidebarActive("reports/marketing") },
                            { name: "Breakdown report", onClick: () => animateNavigate("/reports/breakdown"), active: checkSidebarActive("reports/breakdown") },
                            { name: "Facebook export report", onClick: () => animateNavigate("/reports/facebook"), active: checkSidebarActive("reports/facebook") },
                            (userInfoSelector?.Flags?.isAdmin ? { name: "Designers performance", onClick: () => animateNavigate("/reports/designers"), active: checkSidebarActive("reports/designers") } : null),
                        ].filter(f => f)}
                        onOpenChangeCB={e => setReportsVisible(!!e)}
                        dropdownFunctions={e => reportFunctionsRef.current = e}
                    />
                </div>
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canViewCreatives) && <div onClick={() => animateNavigate("/creatives")} className={`component__sidebar__item ${checkSidebarActive("creatives") ? "component__sidebar__item--active" : ""}`}>
                    <Creatives_img />
                    <p>Creatives</p>
                </div>}
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.creatives_isDesigner) && <div onClick={() => animateNavigate("/creative-designer")} className={`component__sidebar__item ${checkSidebarActive("creative-designer") ? "component__sidebar__item--active" : ""}`}>
                    <Creatives_img />
                    <p>Creatives designer</p>
                </div>}
                {userIntegrationsSelector?.jp && <div onClick={(e) => {
                    e.stopPropagation();
                    let tmp = document.querySelector(".component__sidebar__item--sm");
                    if (tmp) tmp.click();
                }} className={`component__sidebar__item ${checkSidebarActive("socialmanager") ? "component__sidebar__item--active" : ""}`}>
                    <SocialManager_img />
                    <p>Social manager</p>
                    <ButtonWithDropdown
                        headClassName="component__sidebar__item--sm"
                        headStyle={{ backgroundColor: socialMediaVisible ? (checkSidebarActive("socialmanager") ? "#6C5DD3" : (themeSelector === "dark" ? "#232630" : "#F8F9FA")) : "transparent", pointerEvents: socialMediaVisible ? null : "none" }}
                        data={[
                            { name: "Get engagement", onClick: () => animateNavigate("/socialmanager/engage"), active: checkSidebarActive("socialmanager/engage") }
                        ]}
                        onOpenChangeCB={e => setSocialMediaVisible(!!e)}
                        dropdownFunctions={e => socialMediaFunctionsRef.current = e}
                    />
                </div>}
                <div onClick={() => animateNavigate("/offers")} className={`component__sidebar__item ${checkSidebarActive("offers") ? "component__sidebar__item--active" : ""}`}>
                    <Offers_img />
                    <p>Offers</p>
                </div>
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canViewOfferCopies || userInfoSelector?.Flags?.canManageOfferCopies) && <div onClick={() => animateNavigate("/offer-copies")} className={`component__sidebar__item ${checkSidebarActive("offer-copies") ? "component__sidebar__item--active" : ""}`}>
                    <OfferCopies_img />
                    <p>Offer copies</p>
                </div>}
                <div onClick={() => animateNavigate("/sites")} className={`component__sidebar__item ${checkSidebarActive("sites") ? "component__sidebar__item--active" : ""}`}>
                    <Sites_img />
                    <p>Sites</p>
                </div>
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddBalances) && <div onClick={() => animateNavigate("/accountBalances")} className={`component__sidebar__item ${checkSidebarActive("accountBalances") ? "component__sidebar__item--active" : ""}`}>
                    <BalanceManagement_img />
                    <p>Account balances</p>
                </div>}
            </> : <>
                <div onClick={() => animateNavigate("/")} className={`component__sidebar__item ${checkSidebarActive("") ? "component__sidebar__item--active" : ""}`}>
                    <Campaigns_img />
                    <p>Campaigns</p>
                </div>
                {userInfoSelector?.Flags?.isSocialMediaManager && <div onClick={() => animateBox(<CreateLeadModal />)} className={`component__sidebar__item ${checkSidebarActive("/undefined") ? "component__sidebar__item--active" : ""}`}>
                    <Offers_img />
                    <p>Create lead</p>
                </div>}
            </>}
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isAccountManager) && <div onClick={(e) => {
                e.stopPropagation();
                let tmp = document.querySelector(".component__sidebar__item--am");
                if (tmp) tmp.click();
            }} className={`component__sidebar__item ${checkSidebarActive("account-manager") ? "component__sidebar__item--active" : ""}`}>
                <AccountManagement_img />
                <p>Account manager</p>

                <ButtonWithDropdown
                    headClassName="component__sidebar__item--am"
                    headStyle={{ backgroundColor: socialMediaVisible ? (checkSidebarActive("account-manager") ? "#6C5DD3" : (themeSelector === "dark" ? "#232630" : "#F8F9FA")) : "transparent", pointerEvents: accountManagerVisible ? null : "none" }}
                    data={[
                        { name: "Accounts", onClick: () => animateNavigate("/account-manager/accounts"), active: checkSidebarActive("account-manager/accounts") },
                        { name: "Media buyer summary", onClick: () => animateNavigate("/account-manager/mediaBuyerCampaignSummary"), active: checkSidebarActive("account-manager/mediaBuyerCampaignSummary") },
                        { name: "Media buyer campaigns", onClick: () => animateNavigate("/account-manager/mediaBuyerCampaigns"), active: checkSidebarActive("account-manager/mediaBuyerCampaigns") },
                        { name: "Data store", onClick: () => animateNavigate("/account-manager/datastore"), active: checkSidebarActive("account-manager/datastore") },
                        { name: "Reported URL-s", onClick: () => animateNavigate("/account-manager/reportedurls"), active: checkSidebarActive("account-manager/reportedurls") }
                    ]}
                    onOpenChangeCB={e => setAccountManagerVisible(!!e)}
                    dropdownFunctions={e => accountManagerFunctionsRef.current = e}
                />
            </div>}
            {(userInfoSelector?.Flags?.isAdmin || curURLParams["_admin-viewbyid"]) && <div onClick={() => animateNavigate("/integrations")} className={`component__sidebar__item ${checkSidebarActive("integrations") ? "component__sidebar__item--active" : ""}`}>
                <Integrations_img />
                <p>Integrations</p>
            </div>}
            {(userInfoSelector?.Flags?.isAdmin) && <div onClick={() => animateNavigate("/admin-globalIntegrations")} className={`component__sidebar__item ${checkSidebarActive("admin-globalIntegrations") ? "component__sidebar__item--active" : ""}`}>
                <Integrations_img />
                <p>Global integrations</p>
            </div>}

            {userInfoSelector?.Flags?.isTeamLeader && <>
                <div className="component__sidebar__line"></div>
                <div className="component__sidebar__text">
                    <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Team leader</span>
                    <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>TL</span>
                </div>

                <div onClick={() => animateNavigate("/team-usergroups")} className={`component__sidebar__item ${checkSidebarActive("team-usergroups") ? "component__sidebar__item--active" : ""}`}>
                    <UserGroups_img />
                    <p>User groups</p>
                </div>
                <div onClick={() => animateNavigate("/team-users")} className={`component__sidebar__item ${checkSidebarActive("team-users") ? "component__sidebar__item--active" : ""}`}>
                    <Users_img />
                    <p>Users</p>
                </div>
            </>}


            {userInfoSelector?.Flags?.isAdmin && <>
                <div className="component__sidebar__line"></div>
                <div className="component__sidebar__text">
                    <span style={{ opacity: props.sidebarOpened ? '1' : '0', maxWidth: props.sidebarOpened ? '70px' : '0px' }}>Admin</span>
                    <span className="component__sidebar__text--short" style={{ opacity: props.sidebarOpened ? '0' : '1', maxWidth: props.sidebarOpened ? '0px' : '70px' }}>Adm</span>
                </div>

                <div onClick={() => animateNavigate("/admin-users")} className={`component__sidebar__item ${checkSidebarActive("admin-users") ? "component__sidebar__item--active" : ""}`}>
                    <Users_img />
                    <p>All users</p>
                </div>
                <div onClick={() => animateNavigate("/admin-datastore")} className={`component__sidebar__item ${checkSidebarActive("admin-datastore") ? "component__sidebar__item--active" : ""}`}>
                    <Datastore_img />
                    <p>Data store</p>
                </div>

                <div onClick={() => animateNavigate("/admin-apiTokens")} className={`component__sidebar__item ${checkSidebarActive("admin-apiTokens") ? "component__sidebar__item--active" : ""}`}>
                    <ApiTokens_img />
                    <p>API tokens</p>
                </div>
                <div onClick={() => animateNavigate("/admin-globalSharing")} className={`component__sidebar__item ${checkSidebarActive("admin-globalSharing") ? "component__sidebar__item--active" : ""}`}>
                    <GlobalSharing_img />
                    <p>Global sharing</p>
                </div>
                <div onClick={() => animateNavigate("/admin-reportedURLs")} className={`component__sidebar__item ${checkSidebarActive("admin-reportedURLs") ? "component__sidebar__item--active" : ""}`}>
                    <ReportedURL_img />
                    <p>Reported URL-s</p>
                </div>
                <div onClick={() => animateNavigate("/admin-dataChange")} className={`component__sidebar__item ${checkSidebarActive("admin-dataChange") ? "component__sidebar__item--active" : ""}`}>
                    <Data_img />
                    <p>Data change requests</p>
                </div>

                <div onClick={() => animateNavigate("/admin-trackEvents")} className={`component__sidebar__item ${checkSidebarActive("admin-trackEvents") ? "component__sidebar__item--active" : ""}`}>
                    <TrackEvents_img />
                    <p>Track events config</p>
                </div>
                <div onClick={() => animateNavigate("/admin-botDetection")} className={`component__sidebar__item ${checkSidebarActive("admin-botDetection") ? "component__sidebar__item--active" : ""}`}>
                    <BotDetect_img />
                    <p>Bot detection AI</p>
                </div>
            </>}

            {<Sidebar_cronLastUpdate sidebarOpened={props.sidebarOpened} />}
        </div>
        <UserNotifications_popups notifications={props.notifications} />
    </>
};

const ProfileBalance = () => {
    const [data, setData] = React.useState();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const greenColor = themeSelector === "dark" ? "#79c986" : "green";
    const redColor = themeSelector === "dark" ? "#c97979" : "red";

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getBalance`,
            data: {
                IntegrationID: selectedTrackingProfileSelector
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, [selectedTrackingProfileSelector]);

    return <div className="component__sidebar__head__balance">
        {data ? <>
            <p>
                <img src="/images/sidebar/balance_icon.svg" />
                <span>Balance</span>
            </p>
            <span style={{ color: data?.data > 0 ? greenColor : redColor }}>{data.status === "ok" ? `${data.data} ${currencySignSelector}` : "?"}</span>
        </> : <Spinner style={{ width: "17px", height: "17px" }} color="white" />}
    </div>
};

let notifiedAccounts = [];
const CheckProofUploaded = (props) => {
    const [hasProof, setHasProof] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const curDefer = useDefer();

    const checkSpent = () => {
        if (!props.integrationID) return;
        if (props.integrationID && !props.integrationID.startsWith("mg-") && !props.integrationID.startsWith("fb-")) return setHasProof(true);
        setHasProof();

        curDefer(() => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/balances/checkSpentProof`,
                data: {
                    IntegrationID: props.integrationID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setHasProof(true);
                } else {
                    setHasProof(false);
                    notifyAccount();
                };
            }).catch(() => {
                setHasProof(true);
            });
        }, 1000);
    };

    const notifyAccount = () => {
        if (notifiedAccounts.includes(props.integrationID)) return;
        let tmp = document.querySelector(".component__header__left");
        if (!tmp) return;

        animateBox({ currentTarget: tmp }, <YesNoModal
            heading="Account spent proof"
            text={[
                "You haven't still added a proof of spent for the last month on this account. Upload the image as soon as possible!",
                <br />,
                <img src="/images/header_balanceProofAdd.svg" style={{ position: "relative", top: "8px", marginRight: "10px", width: "24px", height: "24px" }} />,
                <span>icon in the header</span>
            ]}
            buttonLeftHidden={true}
            isRightButtonNormal={true}
            buttonRightText="Ok"
        />);
        notifiedAccounts.push(props.integrationID);
    };

    React.useEffect(() => {
        checkSpent();
    }, [props.integrationID]);

    const UploadProofModal = (props2) => {
        const [infoP, setInfoP] = React.useState({
            text: "",
            inputs: [],
            hadError: false
        });
        const [spinner, setSpinner] = React.useState(false);
        const [selectedFile, setSelectedFile] = React.useState();

        const [additionalSpent, setAdditionalSpent] = React.useState();
        const [selectedFileAdditional, setSelectedFileAdditional] = React.useState();

        const spentRef = React.useRef();
        const spentAdditionalRef = React.useRef();

        const uploadProof = () => {
            if (spinner) return;

            setInfoP(ip => { return { ...ip, hadError: false, inputs: [] } });
            if (!selectedFile) return setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Image is not selected." } });
            if (!spentRef.current.value) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent"], text: "Spent is missing" } });

            if (additionalSpent) {
                if (!selectedFileAdditional) return setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Additional mage is not selected." } });
                if (!spentAdditionalRef.current.value) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent2"], text: "Additional spent is missing" } });
            };

            let curDate = moment().add(-1, "month").endOf("month").startOf("day");

            let fd = new FormData();
            fd.append("Image", selectedFile);
            fd.append("Amount", spentRef.current.value);
            fd.append("ForDate", curDate.toDate().getTime());
            fd.append("IntegrationID", props.integrationID);
            if (additionalSpent) {
                fd.append("ImageAdditional", selectedFileAdditional);
                fd.append("AmountAdditional", spentAdditionalRef.current.value);
            };

            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/balances/uploadSpentProof`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                if (res.data.status === "ok") {
                    props2.onChange();
                    props2.onClose();
                } else {
                    setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Error while uploading spent proof" } });
                };
            }).catch(() => {
                setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Server timed out while uploading spent proof" } });
            }).finally(() => {
                setSpinner(false);
            });
        };

        return <div className="genericModal">
            <div className="genericModal__wrap">
                <div className="genericModal__wrap__head">
                    <div className="genericModal__wrap__head__left">Upload spent proof</div>
                    <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props2.onClose}></div>
                </div>

                <p>Enter the amount spent for the last month on this account and select an image as a proof.</p>
                <p>{trackingProfilesSelector.find(t => t?.ID === props.integrationID)?.name ?? "-"}</p>
                <br />
                <br />

                <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Amount spent</p>
                    <input ref={spentRef} type="number" placeholder="Amount spent" />
                </div>

                <div className="genericModal__wrap__input">
                    <StyledButton onClick={e => e.target.parentNode.querySelector("input[type=file]").click()}>{selectedFile ? `Change image (${selectedFile?.name})` : "Select image"}</StyledButton>
                    <input type="file" accept="image/*" onChange={e => setSelectedFile(e?.target?.files[0])} style={{ display: "none" }} />
                </div>


                <div className="genericModal__wrap__input" style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "row" }}>
                    <Checkbox onChange={e => setAdditionalSpent(!!e)} />
                    <span>Additional spent?</span>
                </div>
                {additionalSpent && <>
                    <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent2") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Additional amount spent</p>
                        <input ref={spentAdditionalRef} type="number" placeholder="Additional amount spent" />
                    </div>

                    <div className="genericModal__wrap__input">
                        <StyledButton onClick={e => e.target.parentNode.querySelector("input[type=file]").click()}>{selectedFileAdditional ? `Change additional image (${selectedFileAdditional?.name})` : "Select additional image"}</StyledButton>
                        <input type="file" accept="image/*" onChange={e => setSelectedFileAdditional(e?.target?.files[0])} style={{ display: "none" }} />
                    </div>
                </>}


                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props2.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && uploadProof()}>
                        {spinner ? <Spinner style={{ width: "19px", height: "19px" }} color="white" /> : "Upload"}
                    </div>
                </div>

                <p className="genericModal__wrap__infoP" style={{ opacity: infoP.hadError ? 1 : 0 }}>
                    {infoP?.text && infoP.text}
                </p>
            </div>
        </div>
    };

    if (spinner || hasProof === undefined) return <Spinner style={{ width: "20px", height: "20px" }} color="white" />
    if (!hasProof) return <>
        <div
            className="component__sidebar__head__balance component__sidebar__head__clickable"
            onClick={(e) => animateBox({ currentTarget: e?.target?.parentNode }, <UploadProofModal onChange={checkSpent} />)}
        >
            <p>
                <img src="/images/header_balanceProofAdd.svg" />
                <span>Add spend proof</span>
            </p>
        </div>
    </>;
    return null;
};

const UserNotifications = (props) => {
    const [notifications, setNotifications] = React.useState([]);
    const [unreadNotifications, setUnreadNotifications] = React.useState([]);
    const [oldState, setOldState] = React.useState();

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
    };

    const getButtonText = (elem, e) => {
        switch (elem.Action) {
            case 'open-sites':
                return <button onClick={(e) => {
                    animateNavigate('/sites');
                    props.setNotificationsMenu(false);
                }}>Go to sites page</button>
            case 'open-creatives':
                return <button onClick={(e) => {
                    animateNavigate('/creatives');
                    props.setNotificationsMenu(false);
                }}>Go to creatives page</button>
            case 'change-password':
                return <button onClick={(e) => {
                    // animateBox(e, <ChangePassword />);
                    props.setNotificationsMenu(false);
                }}>Change password</button>
            case 'open-integrations':
                return <button onClick={(e) => {
                    animateNavigate('/integrations');
                    props.setNotificationsMenu(false);
                }}>Open integrations</button>
            case "download-report":
                return <button onClick={e => {
                    window.open(`${backendModule.backendURL}/staticContent/reports/${elem.AdditionalData?.ReportFile}`, "_blank");
                }}>Download report</button>
        }
    };

    React.useEffect(() => {
        if (!props.notifications) return;
        let unread = [];
        let read = [];
        for (let not of props.notifications.data) {
            if (!not.Read) {
                unread.push(not);
            } else {
                read.push(not);
            }
        }
        setUnreadNotifications(unread);
        setNotifications(read);
    }, [props.notifications])

    React.useEffect(() => {
        let os = oldState;
        setOldState(props.notificationsMenu);
        if (props.notificationsMenu !== false && os !== true) return;
        if (unreadNotifications.length === 0) return;
        for (let not of unreadNotifications) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/notifications/updateNotification`,
                data: {
                    ID: not.ID
                },
                ...backendModule.axiosConfig
            }).then(() => {
                setUnreadNotifications([]);
                setNotifications(props.notifications.data)
            }).catch(() => {
                return null;
            })
        }

    }, [props.notificationsMenu]);

    React.useEffect(() => {
        const closeDropdown = () => {
            if (props.notificationsMenu) props.setNotificationsMenu(false);
        };

        window.addEventListener("click", closeDropdown);

        let sidebar = document.querySelector(".component__sidebar");
        if (sidebar) {
            sidebar.addEventListener("mouseleave", closeDropdown);
        };

        return () => {
            try {
                window.removeEventListener("click", closeDropdown);
                sidebar.removeEventListener("mouseleave", closeDropdown);
            } catch { };
        };
    }, [props.notificationsMenu]);

    React.useEffect(() => {
        props.setHasUnreadNotifications(unreadNotifications?.length ?? 0);
    }, [unreadNotifications]);

    return <div className={props.notificationsMenu ? `component__sidebar__head__notifications component__sidebar__head__notifications-active ` : 'component__sidebar__head__notifications'} onClick={e => e?.stopPropagation()} style={{...(props.style ?? {})}}>
        <p className="component__sidebar__head__notifications__head">Recent notifications</p>
        <div className="component__sidebar__head__notifications__single">
            {unreadNotifications && <p className="component__sidebar__head__notifications__single__head">
                Unread ({unreadNotifications.length})</p>}
            {unreadNotifications && unreadNotifications.map(item => {
                return <div className={`component__sidebar__head__notifications__single__notification ${item.Type === "error" ? "component__sidebar__head__notifications__single__notification--error" : ""}`}>
                    <p className="component__sidebar__head__notifications__single__notification__name">
                        {item.Type}
                    </p>
                    <p className="component__sidebar__head__notifications__single__notification__content">
                        {item.Content}
                    </p>
                    <p className="component__sidebar__head__notifications__single__notification__time">
                        {moment(new Date()).diff(moment(item.createdAt), 'minutes') < 60 ? moment(new Date()).diff(moment(item.createdAt), 'minutes') + 'min' :
                            moment(new Date()).diff(moment(item.createdAt), 'hours') < 24 ? moment(new Date()).diff(moment(item.createdAt), 'hours') + 'h' :
                                moment(new Date()).diff(moment(item.createdAt), 'months') < 12 ? moment(new Date()).diff(moment(item.createdAt), 'months') + 'months' :
                                    moment(new Date()).diff(moment(item.createdAt), 'years') + "y"
                        }
                    </p>
                    {getButtonText(item)}
                </div>
            })}

        </div>
        <div className="component__sidebar__head__notifications__single">
            {notifications && <p className="component__sidebar__head__notifications__single__head">
                Older ({notifications.length})</p>}
            {notifications && notifications.map(item => {
                return <div className={`component__sidebar__head__notifications__single__notification ${item.Type === "error" ? "component__sidebar__head__notifications__single__notification--error" : ""}`}>
                    <p className="component__sidebar__head__notifications__single__notification__name">
                        {item.Type}
                    </p>
                    <p className="component__sidebar__head__notifications__single__notification__content">
                        {item.Content}
                    </p>
                    <p className="component__sidebar__head__notifications__single__notification__time">
                        {moment(new Date()).diff(moment(item.createdAt), 'minutes') < 60 ? moment(new Date()).diff(moment(item.createdAt), 'minutes') + 'min' :
                            moment(new Date()).diff(moment(item.createdAt), 'hours') < 24 ? moment(new Date()).diff(moment(item.createdAt), 'hours') + 'h' :
                                moment(new Date()).diff(moment(item.createdAt), 'months') < 12 ? moment(new Date()).diff(moment(item.createdAt), 'months') + 'months' :
                                    moment(new Date()).diff(moment(item.createdAt), 'years') + "y"
                        }
                    </p>
                    {getButtonText(item)}
                </div>
            })}

        </div>

    </div>
};

let closeAllTimeout = null;
const UserNotifications_popups = props => {
    const [notifications, setNotifications] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const popupRef = React.useRef();

    const markNotificationAsRead = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/notifications/updateNotificationPopup`,
            data: {
                ID
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            if (popupRef.current) {
                let elem = popupRef.current.querySelector(`[data-id="${ID}"]`);
                if (elem) {
                    let cmp = getComputedStyle(elem);
                    elem.animate([
                        {
                            marginTop: cmp.marginTop,
                            maxHeight: cmp.height,
                            padding: cmp.padding,
                            boxShadow: cmp.boxShadow
                        },
                        {
                            marginTop: "0px",
                            maxHeight: "0",
                            padding: "0 10px",
                            boxShadow: "none"
                        }
                    ], {duration: 300, iterations: 1, fill: "both", easing: "ease"}).onfinish = () => {
                        setNotifications(n => n.map(nn => {
                            let tmp = {...nn};
                            if (tmp.ID === ID) tmp.isPopup = false;
                            return tmp;
                        }));
                    };
                    return;
                };
            };
            setNotifications(n => n.map(nn => {
                let tmp = {...nn};
                if (tmp.ID === ID) tmp.isPopup = false;
                return tmp;
            }));
        });
    };

    const markAllNotificationsAsRead = () => {
        notifications.filter(n => n.isPopup).forEach(n => markNotificationAsRead(n.ID));
    };

    React.useEffect(() => {
        if (props?.notifications?.status === "ok") setNotifications(props.notifications.data);
    }, [props.notifications]);

    React.useLayoutEffect(() => {
        if (!popupRef.current) return;
        let toFind = notifications.filter(n => n.isPopup);

        for (let item of toFind) {
            let elem = popupRef.current.querySelector(`[data-id="${item.ID}"]`);
            if (!elem) continue;

            let cmp = getComputedStyle(elem);
            elem.animate([
                {
                    marginTop: cmp.marginTop,
                    maxHeight: cmp.maxHeight,
                    padding: cmp.padding,
                    boxShadow: cmp.boxShadow
                },
                {
                    marginTop: "10px",
                    maxHeight: "400px",
                    padding: "10px",
                    boxShadow: "0 0 4px 2px rgba(0, 0, 0, 0.25)"
                }
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease"});
        };
    }, [notifications]);

    return <div className="component__sidebar__popups" ref={popupRef}>
        {notifications.filter(n => n.isPopup).map(n => {
            return <div className="component__sidebar__popups__popup" data-id={n.ID} key={n.ID}>
                <div className="component__sidebar__popups__popup__head">
                    <h3 style={{
                        color: n.Type === "error" ? (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight) : null
                    }}>
                        {n.Type.charAt(0).toUpperCase()}{n.Type.substring(1, n.Type.length)}
                        <span>{moment(n.createdAt).toDate().toLocaleString()}</span>
                    </h3>
                    <div
                        style={{backgroundImage: `url("/images/icon_close.svg")`}}
                        onClick={() => markNotificationAsRead(n.ID)}
                        onMouseDown={() => {
                            closeAllTimeout = setTimeout(() => {
                                markAllNotificationsAsRead();
                            }, 1000);
                        }}
                        onMouseUp={() => clearTimeout(closeAllTimeout)}
                    ></div>
                </div>
                <p>{n.Content}</p>
            </div>
        })}
    </div>
};

let cronTimeout = null;
const Sidebar_cronLastUpdate = (props) => {
    let [data, setData] = React.useState();

    const getData = () => {
        clearTimeout(cronTimeout);
        
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/common/getLastCRONTime`,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => setData(backendModule.genericError)).finally(() => {
            cronTimeout = setTimeout(() => getData, 30000);
        });
    };

    React.useEffect(() => {
        clearTimeout(cronTimeout);

        getData();

        return () => {
            clearTimeout(cronTimeout);
        };
    }, []);

    if (!data) return null;
    if (data.status === "error") return null;
    return <p className="component__sidebar__cron" style={{opacity: props.sidebarOpened ? 1 : 0}}>
        <span>Last API data update</span>
        <span>{moment(data.data).toDate().toLocaleString()}</span>
    </p>
};

export default Sidebar;