import React from "react";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { countries } from "../../../modules/countryModule";
import { getParamsFromURLObject } from "../../../modules/urlModule";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import StyledButton from "../../../components/styledComponents/Button";
import StyledInput from "../../../components/styledComponents/Input";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

import YesNoModal from "../../../components/modals/YesNoModal";
import Checkbox from "../../../components/customComponents/Checkbox";

let refreshTS = Date.now();
const UserCreatives_ST = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [search, setSearch] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();
    const [performanceDateFilter, setPerformanceDateFilter] = React.useState();
    const [creativeForFilter, setCreativeForFilter] = React.useState();
    const [creativeTypeFilter, setCreativeTypeFilter] = React.useState();
    const [fileTypeFilter, setFileTypeFilter] = React.useState();
    const [creativeCountryFilter, setCreativeCountryFilter] = React.useState();
    const [userFilter, setUserFilter] = React.useState();

    const [canPaginate, setCanPaginate] = React.useState(false);
    const [selectedCreative, setSelectedCreative] = React.useState(null);
    const [canApplyFilters, setCanApplyFilters] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState();
    const [performance, setPerformance] = React.useState({});

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});
    const creativeTypesSelector = useSelector(state => state?.types?.creativeTypes ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef(Date.now());
    const fileWidth_startRef = React.useRef();
    const fileWidth_endRef = React.useRef();
    const fileHeight_startRef = React.useRef();
    const fileHeight_endRef = React.useRef();

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const curDispatch = useDispatch();

    const getPerformance = (items, groupID, ts) => {
        if (performance[groupID]) return;

        let filters = [];
        if (performanceDateFilter?.start && performanceDateFilter?.end) {
            filters.push({name: "createdAt", op: "pdgeq", value: performanceDateFilter.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: performanceDateFilter.end.toDate().getTime()});
        };
        
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getCreativeStats`,
            data: {
                IDs: items.map(i => i.ID),
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (refreshTS !== ts) return;
            if (res.data.status === "ok") {
                return setPerformance(p => {
                    return {
                        ...p,
                        [groupID]: res.data.data
                    };
                });
            };
            return setPerformance(p => {
                return {
                    ...p,
                    [groupID]: {Visits: 0, CR: 0, CTR: 0}
                };
            });
        }).catch(() => {
            if (refreshTS !== ts) return;
            return setPerformance(p => {
                return {
                    ...p,
                    [groupID]: {Visits: 0, CR: 0, CTR: 0}
                };
            });
        });
    };

    const prepareFilters = () => {
        let out = [];
        if (!search) return out;

        for (let item of search.split(" ")) {
            out.push({or: [
                {name: "CreativeName", op: "like", value: item},
                {name: "FileType", op: "like", value: item},
                {name: "CustomID", op: "like", value: item}
            ]})
        };

        return out.length === 0 ? null : {and: out};
    };

    const getAllCreativeNames = ts => {
        if (timestampRef.current !== ts) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreativeNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames(res.data.status === "ok" ? res.data.data : []);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames([]);
        });
    };

    const getData = (ts, params) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);
        setPerformance({});
        getAllCreativeNames(ts);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()}
            ]});
        };

        let additionalFilters = [];
        if (creativeCountryFilter) {
            additionalFilters.push({name: "Country", op: "eq", value: creativeCountryFilter});
        };
        if (fileTypeFilter) {
            additionalFilters.push({name: "FileType", op: "eq", value: fileTypeFilter});
        };
        if (creativeTypeFilter) {
            additionalFilters.push({name: "CreativeType", op: "eq", value: creativeTypeFilter});
        };
        if (fileWidth_startRef.current.value || fileWidth_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileWidth_startRef.current.value) {
                start = Number(fileWidth_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileWidth_endRef.current.value) {
                end = Number(fileWidth_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeWidth", op: "geq", value: start} : null),
                (end ? {name: "CreativeWidth", op: "leq", value: end} : null)
            ].filter(f => f)});
        };
        if (fileHeight_startRef.current.value || fileHeight_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileHeight_startRef.current.value) {
                start = Number(fileHeight_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileHeight_endRef.current.value) {
                end = Number(fileHeight_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeHeight", op: "geq", value: start} : null),
                (end ? {name: "CreativeHeight", op: "leq", value: end} : null)
            ].filter(f => f)});
        };
        if (userFilter) additionalFilters.push({name: "CreatedBy", op: "eq", value: userFilter});

        setSpinner(true);
        setPerformance({});
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    ...additionalFilters,
                    ((selectedCreative || params?.angle) ? {name: "CreativeName", op: "eq", value: selectedCreative || params?.angle} : null),
                    (creativeForFilter ? {name: "CreativeFor", op: "eq", value: creativeForFilter} : null),
                    (params?.["IDs"] ? {name: "ID", op: "in", value: params["IDs"]} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setCanApplyFilters(false);
            setSpinner(false);
        });
    };

    const continueData = (ts) => {
        if (!canPaginate) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()}
            ]});
        };

        let additionalFilters = [];
        if (creativeCountryFilter) {
            additionalFilters.push({name: "Country", op: "eq", value: creativeCountryFilter});
        };
        if (fileTypeFilter) {
            additionalFilters.push({name: "FileType", op: "eq", value: fileTypeFilter});
        };
        if (creativeTypeFilter) {
            additionalFilters.push({name: "CreativeType", op: "eq", value: creativeTypeFilter});
        };
        if (fileWidth_startRef.current.value || fileWidth_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileWidth_startRef.current.value) {
                start = Number(fileWidth_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileWidth_endRef.current.value) {
                end = Number(fileWidth_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeWidth", op: "geq", value: start} : null),
                (end ? {name: "CreativeWidth", op: "leq", value: end} : null)
            ].filter(f => f)});
        };
        if (fileHeight_startRef.current.value || fileHeight_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileHeight_startRef.current.value) {
                start = Number(fileHeight_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileHeight_endRef.current.value) {
                end = Number(fileHeight_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeHeight", op: "geq", value: start} : null),
                (end ? {name: "CreativeHeight", op: "leq", value: end} : null)
            ].filter(f => f)});
        };
        if (userFilter) additionalFilters.push({name: "CreatedBy", op: "eq", value: userFilter});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    ...additionalFilters,
                    {not: [
                        {name: "ID", op: "in", value: data.data.map(d => d.ID)},
                    ]},
                    (selectedCreative ? {name: "CreativeName", op: "eq", value: selectedCreative} : null),
                    (creativeForFilter ? {name: "CreativeFor", op: "eq", value: creativeForFilter} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const removeCreative = (e, item) => {
        let target = {currentTarget: e?.target};

        animateBox(target, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove a creative, are You sure?", <br/>, `It will also remove the whole group (${item._allCreatives.length} creative${item._allCreatives.length > 1 ? "s" : ""})`, <br />, "This action is irreversible"]}
            buttonLeftText={"No"}
            buttonRightText={"Yes"}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                let hadError = false;

                for (let tmp of item._allCreatives) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/creatives/removeCreative`,
                        data: {
                            ID: tmp.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "error") hadError = true;
                    }).catch(() => {
                        hadError = true;
                    });
                };

                args.spinner(false);
                args.disabledAll(false);
                if (hadError) {
                    args.errorMessage("There was an error while removing one or more creatives. They will be shown in the creatives list.");
                } else {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    curDefer(() => getData(ts), 0);

                    args.close();
                };
            }}
        />);
    };

    const checkAdditionalData = (ad, creative, noWrap = false) => {
        if (!ad) return null;
        if (!ad?.["integration"]) {
            if (noWrap) return creative?.CustomID;
            return <span style={{
                color: "gray",
                marginRight: "auto",
                fontSize: "14px"
            }}>{creative?.CustomID}</span>;
        };
        let nameSplit = String(ad["integration"]).split(":");

        let intName = nameSplit.shift();
        let intValue = nameSplit.join(":");

        if (noWrap) return `${intName.charAt(0)}-${intValue}`;
        return <span style={{
            color: "gray",
            marginRight: "auto",
            fontSize: "14px"
        }}>{intName.charAt(0)}{`-${intValue}`}</span>;
    };

    const groupCreatives = () => {
        if (!data) return [];
        if (data.status !== "ok") return [];

        let creatives = {};
        for (let item of data.data) {
            if (!creatives[item.GroupID]) creatives[item.GroupID] = [];

            creatives[item.GroupID].push(item);
        };

        let out = [];
        for (let item of Object.keys(creatives)) {
            out.push({
                ...creatives[item][0],
                _allCreatives: creatives[item]
            });
        };

        return out;
    };

    const getCountryIcon = (item) => {
        if (!item) return null;
        if (!item.Country) return null;

        let additionalFlags = [];

        for (let c of item._allCreatives) {
            if (c.Country !== item.Country) {
                if (!additionalFlags.includes(c.Country)) additionalFlags.push(c.Country);
            };
        };

        return <div className="route__user__creatives__st__content__item__image__flag">
            <div className="route__user__creatives__st__content__item__image__flag__circle">
                <img src={`/images/countryFlags/${item.Country.toLowerCase()}.png`} />
            </div>

            {additionalFlags.length > 0 && <div className="route__user__creatives__st__content__item__image__flag__count">+{additionalFlags.length}</div>}
        </div>;
    };

    const getAllUsers = () => {
        if (!userInfoSelector?.Flags?.isAdmin) return setAllUsers({status: "ok", data: []});
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => setAllUsers(res.data)).catch(() => setAllUsers(backendModule.genericError));
    };

    const getUserBubble = uid => {
        if (!uid) return null;
        if (!userInfoSelector?.Flags?.isAdmin) return null;
        if (!allUsers) return null;
        if (allUsers.status !== "ok") return null;

        let curUser = allUsers.data.find(usr => usr.ID === uid);
        if (!curUser) return null;

        let userSplit = [];
        if (curUser.Username.includes(".")) {
            userSplit = curUser.Username.split(".").filter(f => f);
        } else {
            userSplit = [curUser.Username.charAt(0), curUser.Username.charAt(curUser.Username.length-1)];
        };

        return <p className="route__user__creatives__st__content__item__buttons__user" title={curUser.Username}>
            {userSplit.map(u => u.toUpperCase()).join("")}
        </p>;
    };

    const getURLFilters = () => {
        let curParams = getParamsFromURLObject(String(window.location));
        if (curParams.angle) {
            setSelectedCreative(curParams.angle);
        };
        if (curParams["IDs"]) {
            curParams["IDs"] = String(curParams["IDs"]).split(",").filter(f => f)
        };

        return curParams;
    };

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts, getURLFilters()), 500);
        };
        setCanApplyFilters(true);
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [search, selectedCreative, dateFilter, performanceDateFilter, creativeForFilter, fileTypeFilter, creativeTypeFilter, creativeCountryFilter, userFilter]);

    React.useEffect(() => {
        if (data?.status !== "ok") return;

        let creatives = groupCreatives();
        (async () => {
            let ts = Date.now();
            refreshTS = ts;
            for (let group of creatives) {
                if (ts !== refreshTS) break;
                if (!performance[group.GroupID]) {
                    await getPerformance(group._allCreatives, group.GroupID, ts);
                    break;
                };
            };
        })()
    }, [data, performance]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        getAllUsers();
    }, [userInfoSelector]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts, getURLFilters());
    }, []);

    return <div className="route__user__creatives__st">

        <div className="route__user__creatives__st__content">
            <div className="route__user__creatives__st__content__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" align="center" />
            </div>
            {data ? <>
                {data.status === "ok" ? <>
                    {groupCreatives().map(item => {
                        return <div className="route__user__creatives__st__content__item" key={`creative-${item.ID}`} onClick={e => {
                            animateBox(<CreativeModal item={item} allUsers={allUsers?.status === "ok" ? allUsers.data : []} performanceDateFilter={performanceDateFilter} />)
                        }}>
                            <div className="route__user__creatives__st__content__item__header">
                                {checkAdditionalData(item?.AdditionalData, item, false)}
                                <span>{creativeForTypesSelector[item.CreativeFor]}</span>
                            </div>

                            <div className="route__user__creatives__st__content__item__image">
                                {(()=>{
                                    switch (item.FileType) {
                                        case "image": return <img src={item.File} />
                                        case "video": return <video src={item.File} />
                                        default: return <img src="/images/fileTypes/file.svg" />
                                    };
                                })()}

                                {getCountryIcon(item)}
                            </div>

                            <div className="route__user__creatives__st__content__item__performance">
                                <p>Visits</p>
                                <p>CR</p>
                                <p>CTR</p>

                                <p>{performance?.[item.GroupID] ? (performance[item.GroupID]?.Visits ?? "0") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                                <p>{performance?.[item.GroupID] ? (`${performance[item.GroupID]?.CR} %` ?? "0 %") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                                <p>{performance?.[item.GroupID] ? (`${performance[item.GroupID]?.CTR} %` ?? "0 %") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>

                            <div className="route__user__creatives__st__content__item__buttons">
                                {userInfoSelector?.Flags?.isAdmin && getUserBubble(item.CreatedBy)}

                                {item.FileType === "file" && <div className="route__user__creatives__st__content__item__buttons__btn" onClick={e => {
                                    e.stopPropagation();
                                    window.open(item.File, "_blank");
                                    navigator.clipboard.writeText(checkAdditionalData(item?.AdditionalData ?? {}, item, true));
                                }} style={{backgroundImage: `url("images/icon_export.svg")`}} />}
                                {(item.FileType === "video" || item.FileType === "image") && <div className="route__user__creatives__st__content__item__buttons__btn" onClick={e => window.open((()=>{
                                    navigator.clipboard.writeText(checkAdditionalData(item?.AdditionalData ?? {}, item, true));
                                    e.stopPropagation();
                                    let tmpURL = item.File;
                                    tmpURL += tmpURL.includes("?") ? "&" : "?";
                                    tmpURL += "download=1";
                                    return tmpURL;
                                })(), "_blank", "download")} style={{backgroundImage: `url("images/icon_export.svg")`}} />}
                                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.ID === item.CreatedBy) && <div className="route__user__creatives__st__content__item__buttons__btn" style={{backgroundImage: `url("/images/integrations/integration_remove.svg")`}} onClick={(e) => {
                                    e.stopPropagation();
                                    removeCreative(e, item);
                                }} />}
                            </div>
                        </div>
                    })}

                    {canPaginate && <div style={{opacity: 0, paddingBottom: "10px", marginBottom: "10px"}} ref={curOnScreen.measureRef}></div>}
                    {data.data.length === 0 && <p>Nothing to show...</p>}
                </> : <p className="route__user__creatives__st__content__infoP">Error while fetching data!</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
        </div>

        <div className="route__user__creatives__st__sideFilters">
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) && <StyledButton onClick={e => animateBox(e, <AddCreative onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                curDefer(() => getData(ts), 0);
            }} />)}>
                <img src="/images/icon_close.svg" />
                <span>Add creative</span>
            </StyledButton>}
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.creatives_canRequest) && <StyledButton
                style={{backgroundColor: "#35373C"}}
                onClick={() => animateBox(<UserCreatives_ST_Request onChange={() => {
                    setCanApplyFilters(false);
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)}
            >Request creative</StyledButton>}

            {canApplyFilters && <StyledButton onClick={() => {
                setCanApplyFilters(false);
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
            }}>Apply filters</StyledButton>}


            <FilterBySearch onChange={e => setSearch(e)} />
            <FilterByDate text="Performance date" disableAll={true} disable24h={true} defaultValue="7days" onChange={e => setPerformanceDateFilter(e)} />
            <FilterByDate text="Creative created date" defaultValue="all" onChange={e => setDateFilter(e)} />

            <AdvancedDropdown
                headline="Illness / Angle"
                showSearch={true}
                data={Array.isArray(allCreativeNames) ? [{key: "all", name: "All", value: null},...allCreativeNames.map(c => {
                    return {
                        key: c.CreativeName,
                        name: `${c.CreativeName} (${c.Count})`,
                        value: c.CreativeName
                    };
                })] : null}
                onChange={e => {
                    if (selectedCreative !== e?.value) setSelectedCreative(e?.value);
                }}
                selected={(()=>{
                    if (selectedCreative === undefined) return null;
                    if (!Array.isArray(allCreativeNames)) return null;
                    if (selectedCreative === null) return 0;

                    return allCreativeNames.indexOf(allCreativeNames.find(ac => ac.CreativeName === selectedCreative)) + 1;
                })()}
            />

            <AdvancedDropdown
                headline="For"
                data={[{key: "all", name: "All", value: null},...Object.keys(creativeForTypesSelector).map(key => {
                    return {key: key, name: creativeForTypesSelector[key], value: key}
                })]}
                onChange={e => creativeForFilter !== e?.value && setCreativeForFilter(e?.value)}
                selected={(()=>{
                    if (!creativeForFilter) return 0;
                    return Object.keys(creativeForTypesSelector).indexOf(creativeForFilter) + 1;
                })()}
            />

            <AdvancedDropdown
                headline="Creative type"
                data={[
                    {name: "All", value: null},
                    ...creativeTypesSelector.map(t => {
                        return {name: t, value: t}
                    })
                ]}
                onChange={e => creativeTypeFilter !== e?.value && setCreativeTypeFilter(e?.value)}
                selected={(()=>{
                    if (!creativeTypeFilter) return 0;
                    return creativeTypesSelector.indexOf(creativeTypeFilter) + 1;
                })()}
            />

            <AdvancedDropdown
                headline="File type"
                data={[
                    {key: "any", name: "All", value: null},
                    {key: "image", name: "Image", value: "image"},
                    {key: "video", name: "Video", value: "video"}
                ]}
                onChange={e => fileTypeFilter !== e?.value && setFileTypeFilter(e?.value)}
                selected={(()=>{
                    if (!fileTypeFilter) return 0;
                    switch (fileTypeFilter) {
                        case "image": return 1;
                        case "video": return 2;
                        default: return null;
                    };
                })()}
            />

            <AdvancedDropdown
                headline="Country"
                data={[
                    {key: "any", name: "All", value: null},
                    ...countries.map(c => {
                        return {key: c.code, name: c.name, value: c.code, image: `/images/countryFlags/${c.code.toLowerCase()}.png`};
                    })
                ]}
                showSearch={true}
                onChange={e => creativeCountryFilter !== e?.value && setCreativeCountryFilter(e?.value)}
                selected={(()=>{
                    if (!creativeCountryFilter) return 0;
                    return countries.indexOf(countries.find(c => c.code === creativeCountryFilter)) + 1;
                })()}
            />

            {(userInfoSelector?.Flags?.isAdmin && allUsers?.status === "ok") && <AdvancedDropdown
                headline="Created by"
                data={[
                    {key: "any", name: "All", value: null},
                    ...allUsers.data.map(c => {
                        return {key: c.ID, name: c.Username, value: c.ID};
                    })
                ]}
                showSearch={true}
                onChange={e => userFilter !== e?.value && setUserFilter(e?.value)}
                selected={(()=>{
                    if (!allUsers) return 0;
                    if (allUsers?.status !== "ok") return 0;
                    if (!userFilter) return 0;
                    return allUsers.data.indexOf(allUsers.data.find(c => c.ID === userFilter)) + 1;
                })()}
            />}

            <div className="route__user__creatives__st__sideFilters__split">
                <p>File width</p>
                <StyledInput ref={fileWidth_startRef} onChange={() => setCanApplyFilters(true)} placeholder="From" alternateStyle={true} />
                <StyledInput ref={fileWidth_endRef} onChange={() => setCanApplyFilters(true)} placeholder="To" alternateStyle={true} />
            </div>
            <div className="route__user__creatives__st__sideFilters__split">
                <p>File height</p>
                <StyledInput ref={fileHeight_startRef} onChange={() => setCanApplyFilters(true)} placeholder="From" alternateStyle={true} />
                <StyledInput ref={fileHeight_endRef} onChange={() => setCanApplyFilters(true)} placeholder="To" alternateStyle={true} />
            </div>

        </div>
    </div>
};

const UserCreatives_ST_Request = props => {
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        text: ""
    });
    const [spinner, setSpinner] = React.useState(false);
    const [curCountries, setCurCountries] = React.useState(Array.isArray(props.countries) ? props.countries : []);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const countryResetRef = React.useRef();
    const dimensions_widthRef = React.useRef();
    const dimensions_heightRef = React.useRef();
    const descriptionRef = React.useRef();

    const requestCreative = () => {
        if (spinner) return;
        
        let data = {
            ExistingCreativeID: props.ID ?? null,
            Countries: curCountries,
            Dimension_Width: dimensions_widthRef.current.value,
            Dimension_Height: dimensions_heightRef.current.value,
            Description: descriptionRef.current.value.trim()
        };

        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        if (data.Countries.length === 0) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["country"], text: "Select at least 1 country"}});
        if (!data.Dimension_Width || !data.Dimension_Height) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["dimensions"], text: "Dimensions are required"}});
        if (isNaN(data.Dimension_Width) || isNaN(data.Dimension_Height)) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["dimensions"], text: "Dimensions must be a number"}});
        if (Number(data.Dimension_Width) <= 0 || Number(data.Dimension_Height) <= 0) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["dimensions"], text: "Dimensions must be greater than 0"}});
        if (!data.Description) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["message"], text: "Message can't be empty"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/requests/request`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "There was an error while creating a request!"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Request creative</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("country") ? "genericModal__wrap__input--error": ""}`}>
                <p>Country</p>
                <Dropdown
                    inlinePlaceholder="Select one or more countries"
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={countries.map(c => {
                        return {name: c.name, value: c.code};
                    })}
                    onReset={e => countryResetRef.current = e}
                    onChange={e => {
                        if (e?.value && !curCountries.includes(e?.value)) {
                            setCurCountries(c => [...c, e.value]);
                            try { countryResetRef.current?.(); } catch {};
                        };
                    }}
                />

                {curCountries.length > 0 && <div className="route__user__creatives__st__addCreative__pills">
                    {curCountries.map(c => {
                        return <div className="route__user__creatives__st__addCreative__pills__pill" onClick={() => {
                            setCurCountries(cc => cc.filter(ccf => ccf !== c));
                        }}>
                            <img src={`/images/countryFlags/${c.toLowerCase()}.png`} />
                            <span>{c}</span>
                        </div>
                    })}
                </div>}
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--split ${infoP.inputs.includes("dimensions") ? "genericModal__wrap__input--error": ""}`}>
                <p>Dimensions in px (Width, Height)</p>
                <input type="text" placeholder="Width (px)" ref={dimensions_widthRef} defaultValue={props.width ?? ""} />
                <input type="text" placeholder="Height (px)" ref={dimensions_heightRef} defaultValue={props.height ?? ""} />
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--text ${infoP.inputs.includes("message") ? "genericModal__wrap__input--error": ""}`}>
                <p>Message</p>
                <textarea placeholder="Explain your request, what do you need; what do you want the designer to do for you. NOTE: Be as precise as possible!" ref={descriptionRef}></textarea>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && requestCreative()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Request"}
                </div>
            </div>

            {infoP.text && <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>}
        </div>
    </div>
};

const AddCreative = (props) => {
    const [allOffers, setAllOffers] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [files, setFiles] = React.useState([]);
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [creativeFor, setCreativeFor] = React.useState();
    const [creativeType, setCreativeType] = React.useState();
    const [selectedCountries, setSelectedCountries] = React.useState(Array.isArray(props.countries) ? props.countries : []);
    const [fileCountries, setFileCountries] = React.useState([]);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadErrors: false,
        inputs: []
    });
    const [spinner, setSpinner] = React.useState(false);
    const [creativeName, setCreativeName] = React.useState();
    const [ctaCopies, setCTACopies] = React.useState([{cta: "", primary: ""}]);
    const [groupCreatives, setGroupCreatives] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState(1);

    const countryResetRef = React.useRef();
    const illnessResetRef = React.useRef();

    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});
    const creativeTypesSelector = useSelector(state => state?.types?.creativeTypes ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const selectFiles = f => {
        if (!(f instanceof FileList)) return;
        if (f.length === 0) return;

        let toAdd = [];
        for (let item of f) {
            if (!files.find(t => t.name === item.name && t.size === item.size)) toAdd.push(item);
        };
        setFiles(f => [...f, ...toAdd]);
    };

    const addCreative = async (e) => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadErrors: false, inputs: []}});

        let initialData = {
            CreativeName: creativeName,
            CreativeFor: creativeFor,
            CreativeType: creativeType,
            CTACopies: ctaCopies.filter(f => f?.cta || f?.primary)
        };
        let allIDs = [];

        if (!initialData.CreativeName) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Creative angle / offer can't be empty!", inputs: ["name"]}});
        if (!initialData.CreativeFor) return setInfoP(ip => {return {...ip, hadErrors: true, error: "For can't be empty!", inputs: ["for"]}});
        if (!initialData.CreativeType) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Type can't be empty!", inputs: ["type"]}});
        if (selectedCountries.length === 0) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Country can't be empty!", inputs: ["country"]}});
        if (files.length === 0) return setInfoP(ip => {return {...ip, hadErrors: true, error: "At least 1 file has to be selected!", inputs: []}});
        for (let f of files) {
            if (!f.type.startsWith("image/") && !f.type.startsWith("video/")) return setInfoP(ip => {return {...ip, hadErrors: true, error: `File ${f.name} is invalid!`, inputs: []}});
        };

        if (activeTab === 1) {
            setActiveTab(2);
            return;
        };

        let i = 0;
        const failedFiles = [];
        setSpinner(true);
        let groupID = props.customGroupID ?? null;
        for (let file of files) {
            let fileType = null;
            if (file.type.startsWith("image/")) fileType = "image";
            if (file.type.startsWith("video/")) fileType = "video";

            let fd = new FormData();
            if (fileType === "image") {
                fd.append("ImageName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("image", file);
            } else {
                fd.append("FileName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("file", file);
                fd.append("FileType", fileType);
            };

            let upload = await axios({
                method: "POST",
                url: `${backendModule.backendURL}${fileType === "image" ? "/images/uploadImage" : "/files/uploadFile"}`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => res.data.status === "ok"? res.data.data?.url : null).catch(() => null);
            if (!upload) {
                failedFiles.push(file);
                continue;
            };

            for (let c of (fileCountries[files.indexOf(file)] ?? [])) {
                let final = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/creatives/addCreative`,
                    data: {
                        ...initialData,
                        Country: c,
                        File: upload,
                        FileType: fileType,
                        FileName: file.name,
                        GroupID: groupID
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);
    
                if (final.status !== "ok") {
                    failedFiles.push(file);
                    let imgName = upload.split("/").pop();
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}${fileType === "image" ? "/images/deleteImageByFilename" : "/files/deleteFileByFilename"}`,
                        data: {
                            FileName: imgName
                        },
                        ...backendModule.axiosConfig
                    }).catch(() => null);
                    break;
                } else {
                    allIDs.push(final.data.ID);
                    if (!groupID && groupCreatives) groupID = final.data.ID;
                };
            };
        };

        if (failedFiles.length > 0) {
            let errs = [`There was an error while uploading ${failedFiles.length} files!`, <br/>, "The following files could not be uploaded:",<br/>];
            allIDs = [];
            for (let file of failedFiles) {
                errs.push(<span style={{color: "rgb(201, 194, 255)"}}>{file?.name ?? "unknown"}</span>, <br/>);
            };

            animateBox({currentTarget: e?.target}, <YesNoModal
                heading="Error while uploading"
                text={errs}
                buttonLeftHidden={true}
                isRightButtonNormal={true}
                buttonRightText="Ok"
            />);
        };
        setSpinner(false);
        props.onClose();
        props.onChange(allIDs);
    };

    const processCopyText = (index, type, text) => {
        setCTACopies(ctaCopies => {
            let tmp = [...ctaCopies].map(c => {return {...c}});
            tmp[index][type] = text;

            tmp = tmp.filter(tt => tt?.cta || tt?.primary);
            if (tmp.length === 0) {
                tmp.push({cta: "", primary: ""});
            } else {
                if (tmp[tmp.length-1].cta || tmp[tmp.length-1].primary) {
                    tmp.push({cta: "", primary: ""});
                };
            };

            return tmp;
        });
    };

    const renderFileAsImage = (file, dataID) => {
        let fr = new FileReader();
        fr.onload = function () {
            let curItem = document.querySelector(`img[creative-data-id="${dataID}"]`);
            if (!curItem) return;
            curItem.src = fr.result;
        }
        fr.readAsDataURL(file);
    };

    React.useEffect(() => {
        setCreativeName();
        if (illnessResetRef.current) {
            try { illnessResetRef.current(); } catch {};
        };
        if (!allOffers) return setAllCreativeNames({status: "ok", data: []});
        if (allOffers.status === "error") return setAllCreativeNames({status: "ok", data: []});

        let curOffer = allOffers.data.find(o => o.ID === selectedOffer);
        if (!curOffer) return setAllCreativeNames({status: "ok", data: []});
        return setAllCreativeNames({status: "ok", data: curOffer.OfferAngles});
    }, [selectedOffer]);

    React.useEffect(() => {
        if (!files?.length || !selectedCountries?.length) return;

        let out = [];
        for (let _ of files) {
            out.push([...selectedCountries]);
        };
        setFileCountries(out);
    }, [selectedCountries, files]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllOffers(res.data);
        }).catch(() => {
            setAllOffers(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__creatives__st__addCreative genericModal">
        <div className="genericModal__wrap" style={{width: "700px", maxWidth: "100%"}}>
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add creative</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("for") ? "genericModal__wrap__input--error" : ""}`} style={{display: activeTab === 1 ? null : "none"}}>
                <p>For</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="For"
                    data={Object.keys(creativeForTypesSelector).map(key => {
                        return {key, name: creativeForTypesSelector[key], value: key}
                    })}
                    onChange={e => {
                        setCreativeFor(e?.value);
                    }}
                    selected={(()=>{
                        if (!creativeFor) return null;
                        return Object.keys(creativeForTypesSelector).indexOf(creativeFor)
                    })()}
                />
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("type") ? "genericModal__wrap__input--error" : ""}`} style={{display: activeTab === 1 ? null : "none"}}>
                <p>Type</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="For"
                    data={creativeTypesSelector.map(key => {
                        return {key, name: key, value: key}
                    })}
                    onChange={e => {
                        setCreativeType(e?.value);
                    }}
                    selected={(()=>{
                        if (!creativeType) return null;
                        return creativeTypesSelector.indexOf(creativeType)
                    })()}
                />
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("offer") ? "genericModal__wrap__input--error" : ""}`} style={{display: activeTab === 1 ? null : "none"}}>
                <p>Offer</p>
                {allOffers ? <>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Offer"
                        data={[
                            ...(allOffers?.status ===  "ok" ? allOffers.data : []).map(name => {
                                return {name: `${name.OfferName} (${name.Country}, ${name.OfferType})`, value: name.ID};
                            })
                        ]}
                        onChange={e => {
                            setSelectedOffer(e?.value);
                        }}
                        selected={(()=>{
                            if (!allOffers) return null;
                            if (allOffers.status !== "ok") return null;

                            let tmpIndex = allOffers.data.indexOf(allOffers.data.find(a => a.ID === selectedOffer));
                            if (tmpIndex === -1) return null;
                        })()}
                    />
                </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
            </div>

            {selectedOffer && <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`} style={{display: activeTab === 1 ? null : "none"}}>
                <p>Illness / Angle</p>
                {allCreativeNames ? <>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Illness"
                        data={[
                            ...(allCreativeNames?.status ===  "ok" ? allCreativeNames.data : []).map(name => {
                                return {name: name, value: name};
                            })
                        ]}
                        onChange={e => {
                            setCreativeName(e?.value);
                        }}
                        selected={(()=>{
                            if (!allCreativeNames) return null;
                            if (allCreativeNames.status !== "ok") return null;

                            let tmpIndex = allCreativeNames.data.indexOf(allCreativeNames.data.find(a => a === creativeName));
                            if (tmpIndex === -1) return null;
                            return tmpIndex;
                        })()}
                        onReset={r => {
                            illnessResetRef.current = r;
                        }}
                    />
                </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
            </div>}

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("country") ? "genericModal__wrap__input--error" : ""}`} style={{display: activeTab === 1 ? null : "none"}}>
                <p>Country</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Country"
                    data={countries.map(c => {
                        return {name: c.name, value: c.code}
                    })}
                    onChange={e => {
                        if (e?.value) {
                            if (!selectedCountries.includes(e?.value)) setSelectedCountries(sc => [...sc, e?.value]);
                            if (countryResetRef.current) countryResetRef.current();
                        };
                    }}
                    selected={(()=>null)()}
                    onReset={r => {
                        countryResetRef.current = r;
                    }}
                />
                <div className="route__user__creatives__st__addCreative__pills">
                    {selectedCountries.map(c => {
                        return <div className="route__user__creatives__st__addCreative__pills__pill" onClick={() => {
                            setSelectedCountries(sc => sc.filter(scf => scf !== c));
                        }}>
                            <img src={`/images/countryFlags/${c.toLowerCase()}.png`} />
                            <span>{c}</span>
                        </div>
                    })}
                </div>
            </div>

            <div className="genericModal__wrap__input genericModal__wrap__input--split" style={{display: activeTab === 1 ? null : "none"}}>
                <p>Copy text</p>
                
                {ctaCopies.map((cta, ctaIdx) => {
                    return <>
                        <input type="text" placeholder="CTA Copy text" value={cta.cta} onChange={e => processCopyText(ctaIdx, "cta", e.target.value)} />
                        <input type="text" placeholder="Primary Copy text" value={cta.primary} onChange={e => processCopyText(ctaIdx, "primary", e.target.value)} />
                    </>;
                })}
            </div>

            <input type="file" accept="image/*, video/*" style={{display: "none"}} multiple={true} onChange={e => selectFiles(e?.target?.files)} />
            <StyledButton className="route__user__creatives__st__addCreative__uploadBtn" onClick={e => e?.currentTarget?.parentNode?.querySelector?.("input[type=file]")?.click?.()} style={{display: activeTab === 1 ? null : "none"}}>
                <img src="/images/fileTypes/file.svg" />
                <span>Click to upload a file</span>
            </StyledButton>

            <div className="route__user__creatives__st__addCreative__list" style={{display: activeTab === 1 ? null : "none"}}>
                {files.map((f, fIdx) => <div className="route__user__creatives__st__addCreative__list__item">
                    <p>{f?.name}</p>
                    <StyledButton isSecondary={true} onClick={() => {
                        setFiles(f => f.filter((_, f2Idx) => f2Idx !== fIdx));
                    }}>Remove</StyledButton>
                </div>)}
            </div>

            <div className="genericModal__wrap__input" style={{flexDirection: "row", display: activeTab === 1 ? null : "none"}}>
                <Checkbox
                    checked={groupCreatives}
                    onChange={e => {
                        if ((!!e) !== groupCreatives) setGroupCreatives(!!e);
                    }}
                />
                <span>Group creatives</span>
            </div>

            {activeTab === 1 && <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={(e) => !spinner && addCreative(e)}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Next"}
                </div>
            </div>}


            {activeTab === 2 && <div className="route__user__creatives__st__addCreative__uploadFiles">
                {files.map((f, fIdx) => {
                    return <div className="route__user__creatives__st__addCreative__uploadFiles__file">
                        <img src="/images/icon_close.svg" creative-data-id={fIdx} onLoad={() => renderFileAsImage(f, fIdx)} />
                        <div className="route__user__creatives__st__addCreative__uploadFiles__file__countries">
                            {(selectedCountries ?? []).map(c => {
                                let fc = fileCountries[fIdx] ?? [];
                                return <div
                                    className={`route__user__creatives__st__addCreative__uploadFiles__file__countries__country ${fc.includes(c) ? "route__user__creatives__st__addCreative__uploadFiles__file__countries__country--active" : ""}`}
                                    onClick={() => {
                                        if (fc.includes(c)) {
                                            setFileCountries(newFc => {
                                                let tmp = [...newFc];
                                                if (!tmp[fIdx]) tmp[fIdx] = [];
                                                tmp[fIdx] = tmp[fIdx].filter(ff => ff !== c);
                                                return tmp;
                                            });
                                        } else {
                                            setFileCountries(newFc => {
                                                let tmp = [...newFc];
                                                if (!tmp[fIdx]) tmp[fIdx] = [];
                                                tmp[fIdx].push(c);
                                                return tmp;
                                            });
                                        };
                                    }}
                                >
                                    <img src={`/images/countryFlags/${String(c).toLowerCase()}.png`} />
                                    <span>{c}</span>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>}


            {activeTab === 2 && <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => setActiveTab(1)}>Back</div>
                <div className="genericModal__wrap__buttons__btn" onClick={(e) => !spinner && addCreative(e)}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Add"}
                </div>
            </div>}

            <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadErrors ? 1 : 0
            }}>{infoP.error}</p>

        </div>
    </div>
};

const CreativeModal = props => {
    const [selectedCreative, setSelectedCreative] = React.useState(0);
    const [performance, setPerformance] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const mainRef = React.useRef();
    const timestampRef = React.useRef();

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const checkAdditionalData = (ad, creative, noWrap = false) => {
        if (!ad) return null;
        if (!ad?.["integration"]) {
            if (noWrap) return creative?.CustomID;
            return <span style={{
                color: "gray",
                marginRight: "auto",
                fontSize: "14px"
            }}>{creative?.CustomID}</span>;
        };
        let nameSplit = String(ad["integration"]).split(":");

        let intName = nameSplit.shift();
        let intValue = nameSplit.join(":");

        if (noWrap) return `${intName.charAt(0)}-${intValue}`;
        return <span style={{
            color: "gray",
            marginRight: "auto",
            fontSize: "14px"
        }}>{intName.charAt(0)}{`-${intValue}`}</span>;
    };
    const getID = i => checkAdditionalData(i?.AdditionalData ?? {}, i, true);

    const getActiveCreative = () => {
        if (props.item._allCreatives[selectedCreative]) return props.item._allCreatives[selectedCreative];

        return props.item;
    };

    const getUniqueCountries = () => {
        let countries = [getActiveCreative().Country];
        // for (let item of props.item._allCreatives) {
        //     if (!countries.includes(item.Country)) countries.push(item.Country);
        // };

        return countries.filter(c => c);
    };

    const getCreativeStats = (ts) => {
        if (timestampRef.current !== ts) return;

        let filters = [];
        if (props.performanceDateFilter?.start && props.performanceDateFilter?.end) {
            filters.push({name: "createdAt", op: "pdgeq", value: props.performanceDateFilter.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: props.performanceDateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getCreativeStats`,
            data: {
                IDs: [getActiveCreative().ID],
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setPerformance(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setPerformance(backendModule.genericError);
        });
    };

    const getCurUser = () => {
        let cu = props.allUsers.find(u => u.ID === props.item.CreatedBy);

        return cu?.Username ?? "Unknown user";
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        setPerformance();
        getCreativeStats(ts);
    }, [selectedCreative]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    return <div className="route__user__creatives__st__modal" onClick={onClose}>
        <div className="route__user__creatives__st__modal__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="route__user__creatives__st__modal__wrap__top">
                <div className="route__user__creatives__st__modal__wrap__top__left">Creative{(selectedCreative !== null && selectedCreative !== undefined) ? ` ${getID(getActiveCreative())}` : ""}</div>

                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.creatives_canRequest) && <StyledButton style={{
                    height: "100%",
                    marginLeft: "auto",
                    marginRight: "20px"
                }} onClick={() => {
                    let curCountries = getUniqueCountries();
                    let curCreative = getActiveCreative();

                    animateBox(<UserCreatives_ST_Request ID={curCreative.ID} countries={curCountries} width={curCreative.CreativeWidth} height={curCreative.CreativeHeight} />);
                }}>Creative update request</StyledButton>}
                <div className="route__user__creatives__st__modal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__creatives__st__modal__wrap__content">
                <div className="route__user__creatives__st__modal__wrap__content__wrap">
                    <div className="route__user__creatives__st__modal__wrap__content__wrap__left">
                        <div className="route__user__creatives__st__modal__wrap__content__wrap__left__content">
                            {getActiveCreative().FileType === "image" && <img src={getActiveCreative().File} />}
                            {getActiveCreative().FileType === "video" && <video src={getActiveCreative().File} controls />}

                            <div className="route__user__creatives__st__modal__wrap__content__wrap__left__content__flag">
                                <div className="route__user__creatives__st__modal__wrap__content__wrap__left__content__flag__circle">
                                    <img src={`/images/countryFlags/${getActiveCreative().Country.toLowerCase()}.png`} />
                                </div>
                            </div>
                        </div>

                        <div className="route__user__creatives__st__modal__wrap__content__wrap__left__buttons">
                            {props.item._allCreatives.map((_, cIdx) => {
                                return <p className={`route__user__creatives__st__modal__wrap__content__wrap__left__buttons__btn ${selectedCreative === cIdx ? "route__user__creatives__st__modal__wrap__content__wrap__left__buttons__btn--active" : ""}`} onClick={() => setSelectedCreative(cIdx)}>{cIdx + 1}</p>
                            })}
                        </div>

                        <StyledButton style={{marginTop: "5px"}} onClick={() => {
                            let activeCreative = getActiveCreative();
                            switch (getActiveCreative().FileType) {
                                case "file":
                                    window.open(activeCreative.File, "_blank");
                                    navigator.clipboard.writeText(getID(activeCreative));
                                    break;
                                case "image":
                                case "video":
                                    navigator.clipboard.writeText(getID(activeCreative));
                                    window.open((()=>{
                                        let tmpURL = activeCreative.File;
                                        tmpURL += tmpURL.includes("?") ? "&" : "?";
                                        tmpURL += "download=1";
                                        return tmpURL;
                                    })(), "_blank", "download");
                                    break;
                            };
                        }}>Download creative</StyledButton>
                    </div>
                    <div className="route__user__creatives__st__modal__wrap__content__wrap__right">
                        <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi">
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Visits</p>
                                <p>{performance ? (performance.status === "ok" ? (performance.data?.Visits ?? "0") : "-") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Impressions</p>
                                <p>{performance ? (performance.status === "ok" ? (performance.data?.Impressions ?? "0") : "-") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Clicks</p>
                                <p>{performance ? (performance.status === "ok" ? (performance.data?.Clicks ?? "0") : "-") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>CR</p>
                                <p>{performance ? (performance.status === "ok" ? (`${performance.data?.CR} %` ?? "0 %") : "-") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>CTR</p>
                                <p>{performance ? (performance.status === "ok" ? (`${performance.data?.CTR} %` ?? "0 %") : "-") : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</p>
                            </div>
                        </div>

                        <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi">
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>ID</p>
                                <p>{getID(getActiveCreative())}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Illness / Angle</p>
                                <p>{props.item.CreativeName}</p>
                            </div>
                            {props.item?.CreativeType && <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Creative type</p>
                                <p>{props.item.CreativeType.charAt(0).toUpperCase()}{props.item.CreativeType.substring(1, props.item.CreativeType.length)}</p>
                            </div>}
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>File type</p>
                                <p>{getActiveCreative().FileType.charAt(0).toUpperCase()}{getActiveCreative().FileType.substring(1, getActiveCreative().FileType.length)}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Countr{getUniqueCountries().length <= 1 ? "y" : "ies"}</p>
                                <p style={{display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px"}}>
                                    {getUniqueCountries().map(c => {
                                        return <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item__country">
                                            <img src={`/images/countryFlags/${String(c ?? "?").toLowerCase()}.png`} />
                                        </div>
                                    })}
                                </p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>For source</p>
                                <p>{creativeForTypesSelector[props.item.CreativeFor] ?? "?"}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Content dimensions</p>
                                <p>{getActiveCreative().CreativeWidth ?? "0"} x {getActiveCreative().CreativeHeight ?? "0"}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Date added</p>
                                <p>{moment(getActiveCreative().createdAt).toDate().toLocaleString()}</p>
                            </div>

                            {userInfoSelector?.Flags?.isAdmin && <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Uploaded by</p>
                                <p>{getCurUser()}</p>
                            </div>}
                        </div>

                        {getActiveCreative().CTACopies?.length > 0 && <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            style={{columnGap: "40px"}}
                            headers={["Generated by", "CTA text", "Primary text"]}
                            customColumns={["max-content", "max-content", "max-content"]}
                            data={getActiveCreative().CTACopies.map((copy, copyIdx) => {
                                return [
                                    {keyID: copyIdx, type: "text", text: copy.isAI ? "AI" : "User", style: {color: copy.isAI ? "aqua" : "orange"}},
                                    {keyID: copyIdx, type: "text", text: copy.cta, style: {maxWidth: "500px", wordBreak: "break-word"}},
                                    {keyID: copyIdx, type: "text", text: copy.primary, style: {maxWidth: "500px", wordBreak: "break-word"}}
                                ];
                            })}
                        />}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default UserCreatives_ST;
export { CreativeModal, AddCreative };